// Creating a unique set of categories, curtesy of set
//    only ever allowing unique entries to be added.
function dedupeCategories(allMarkdownRemark) {
  const uniqueCategories = new Set();
  allMarkdownRemark.edges.forEach(({ node }) => {
    uniqueCategories.add(node.frontmatter.category);
  });
  return Array.from(uniqueCategories);
}

// Turn a string name into a useable slug
function slugify(_value) {
  // Slugs must be lowercase
  // No spaces at beginning or end (trim)
  // Should only have one dash (-) if multiple spaces
  // Should only have one dash if multiple dashes
  const arr = _value.split('/');
  return arr
    .map(
      (path) =>
        path
          .toString()
          .toLowerCase()
          .replace(/\s+/g, '-') // Replace spaces with -
          .replace(/&/g, '-and-') // Replace & with 'and'
          .replace(/[^\w\-]+/g, '') // Remove all non-word chars
          .replace(/\--+/g, '-') // Replace multiple - with single -
          .replace(/^-+/, '') // Trim - from start of text
          .replace(/-+$/, '') // Trim - from end of text
    )
    .join('/');
}

module.exports = { dedupeCategories, slugify };
